<template>
  <v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%">
   <div class=" my-border">
    <v-card-title class="py-0 my-0 customlightgray">
       <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{tableTitle}}</span>
                    <span style=" border-radius: 22px; font-size:0.6em;"
                        class="custom-color-accent pa-1">{{courtsFiltered.length}}
                    </span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                    <template v-slot:prepend-inner>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on='on' v-bind="attrs" @click="dialog_adv=true" class="text--secondary">mdi-cog</v-icon>
                        </template>
                        <span>{{$t("advanced")}}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3"> 
                <v-btn class="ma-auto pa-auto custom-color-accent" depressed @click="openAdd()" v-if="$checkPermission('Courts.Create')">{{$t("New Court")}}</v-btn>
            </v-col>
            
        </v-row>
      </v-card-title>
      <!-- dialog add court -->
        <v-dialog v-model="dialog" max-width="650px">
          <v-card class="customoffwhite">
            <v-card-title class="justify-center ma-0 customlightgray">
              <span class="font-weight-light">{{
                formTitle
              }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div class="container">
                <div class="row">
                   <v-col cols="12" sm="12" md="12">
                      <v-autocomplete
                        clearable
                        v-model="cu_req.id"
                        :items="requests.map(request=>{return {value:request.id,text:request.reqCode}})"
                        :label="$t('requestid')"
                        :placeholder="$t('requestid')"
                        dense
                        outlined
                        required
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu  :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            prepend-inner-icon="mdi-calendar"
                            v-model="activeCourt.dateAdvised" @input="(value) => activeCourt.dateAdvised = value " 
                            v-on="on"
                            outlined
                            dense
                            :label="$t('Date Advised')"
                            :placeholder="$t('Date Advised')"
                          ></v-text-field>
                        </template>
                        <v-date-picker dark :max="nowDate"
                          v-model="activeCourt.dateAdvised" @input="(value) => activeCourt.dateAdvised = value " 
                        ></v-date-picker>
                      </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu  :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            prepend-inner-icon="mdi-calendar"
                            v-model="activeCourt.dateApplicationFiled	" @input="(value) => activeCourt.dateApplicationFiled	 = value "
                            v-on="on"
                            outlined
                            dense
                            :label="$t('Date Application Filed')"
                            :placeholder="$t('Date Application Filed')"
                          ></v-text-field>
                        </template>
                        <v-date-picker dark :max="nowDate"
                          v-model="activeCourt.dateApplicationFiled	" @input="(value) => activeCourt.dateApplicationFiled	 = value "
                        ></v-date-picker>
                      </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu  :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            prepend-inner-icon="mdi-calendar"
                            v-model="activeCourt.dateOfDecision	" @input="(value) => activeCourt.dateOfDecision	 = value "
                            v-on="on"
                            outlined
                            dense
                            :label="$t('Date of Decision')"
                            :placeholder="$t('Date of Decision')"
                          ></v-text-field>
                        </template>
                        <v-date-picker dark
                          v-model="activeCourt.dateOfDecision	" @input="(value) => activeCourt.dateOfDecision	 = value "
                        ></v-date-picker>
                      </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                        :label="$t('contactname')"
                        :placeholder="$t('contactname')"
                        v-model="activeCourt.contactName"
                        dense
                        outlined
                        required
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                        :label="$t('Court File Number')"
                        :placeholder="$t('Court File Number')"
                        v-model="activeCourt.courtFileNumber"
                        dense
                        outlined
                        required
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                        :label="$t('Contact File Number')"
                        :placeholder="$t('Contact File Number')"
                        v-model="activeCourt.contactFileNumber"
                        dense
                        outlined
                        required
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                        :label="$t('Contact Telephone')"
                        :placeholder="$t('Contact Telephone')"
                        v-model="activeCourt.contactPhone"
                        dense
                        outlined
                        required
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                        grow
                        outlined
                        v-model="activeCourt.courtComment"
                        dense
                        :label="$t('Court Comment')"
                        :placeholder="$t('Court Comment')"
                      ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-select :items="sections" clearable v-model="selectedSection" item-text="name" :label="$t('court type')" :placeholder="$t('court type')" dense return-object outlined></v-select>
                  </v-col>
                  <v-col cols="12" v-if="selectedSection">
                    <v-textarea
                        v-if="selectedSection.id==1"
                        grow
                        outlined
                        dense
                        v-model="activeCourt.section41" 
                        :label="$t('Section 41')"
                        :placeholder="$t('Section 41')"
                    ></v-textarea>
                    <v-textarea
                        v-if="selectedSection.id==2"
                        grow
                        outlined
                        dense
                        v-model="activeCourt.section42"
                        :label="$t('Section 42')"
                        :placeholder="$t('Section 42')"
                    ></v-textarea>
                    <v-textarea
                        v-if="selectedSection.id==3"
                        grow
                        outlined
                        dense
                        v-model="activeCourt.section43" 
                        :label="$t('Section 43')"
                        :placeholder="$t('Section 43')"
                      ></v-textarea>
                  </v-col>
                </div>
              </div>      
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="custom-color-accent-text" text @click="close">{{$t('cancel')}}</v-btn>
              <v-btn :disabled="isDisable" class="custom-color-accent-text" text @click="saveCourt">{{$t('save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <!-- //dialog add court -->

      <!-- dialog advanced search -->
      <v-dialog v-model="dialog_adv" max-width="600px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="font-weight-light">{{$t("advanced")}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0">
              <v-row class="ma-0">
               <v-col cols="12" class="ma-0 py-0" > 
                  <v-autocomplete 
                      v-model="filters.ATIPOfficerId"
                      :items="users.map(user=>{return {value:user.id,text:user.first_name+' '+user.last_name}})"
                      :label="$t('assignee')"
                      :placeholder="$t('assignee')"
                      outlined
                      dense
                      clearable
                      return-object
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-autocomplete
                    small-chips
                    multiple
                    clearable
                    v-model="filters.requestId"
                    :items="requests.map(request=>{return {value:request.id,text:request.reqCode}})"
                    :label="$t('requestid')"
                    :placeholder="$t('requestid')"
                    dense
                    outlined
                    return-object
                  ></v-autocomplete>
               </v-col>
                <v-col cols="12" class="ma-0 py-0"> 
                  <v-text-field class="ma-0" :label="$t('contactname')" :placeholder="$t('contactname')" dense outlined v-model="filters.contactName"></v-text-field>
                </v-col>
               <v-col cols="12" class="ma-0 py-0">  
                  <v-text-field class="ma-0" :label="$t('Court File Number')" :placeholder="$t('Court File Number')" dense outlined v-model="filters.courtFileNumber"></v-text-field>
                </v-col>
               <v-col cols="12" class="ma-0 py-0">
                  <v-text-field class="ma-0" :label="$t('Contact File Number')" :placeholder="$t('Contact File Number')" dense outlined v-model="filters.contactFileNumber"></v-text-field>
                </v-col>
               <v-col cols="12" class="ma-0 py-0">
                  <v-text-field class="ma-0" :label="$t('Court Comment')" :placeholder="$t('Court Comment')" dense outlined v-model="filters.courtComment"></v-text-field>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-menu
                    class="ma-0"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="filters.dateAdvised"
                        multiple
                        chips
                        dense
                        clearable
                        outlined
                        small-chips
                        :label="$t('Date Advised')"
                        :placeholder="$t('Date Advised')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:selection="data">
                          <v-chip :input-value="data.selected" class="custom-color-accent" small>
                            <strong>{{ data.item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </template>
                    <v-date-picker range v-model="filters.dateAdvised" :max="nowDate"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-menu
                    class="ma-0"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="filters.dateApplicationFiled"
                        multiple
                        chips
                        dense
                        clearable
                        outlined
                        small-chips
                        :label="$t('Date Application Filed')"
                        :placeholder="$t('Date Application Filed')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:selection="data">
                          <v-chip :input-value="data.selected" class="custom-color-accent" small>
                            <strong>{{ data.item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </template>
                    <v-date-picker range v-model="filters.dateApplicationFiled" :max="nowDate"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-menu
                    class="ma-0"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="filters.dateOfDecision"
                        multiple
                        chips
                        dense
                        clearable
                        outlined
                        small-chips
                        :label="$t('Date of Decision')"
                        :placeholder="$t('Date of Decision')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:selection="data">
                          <v-chip :input-value="data.selected" class="custom-color-accent" small>
                            <strong>{{ data.item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </template>
                    <v-date-picker range v-model="filters.dateOfDecision" :max="nowDate"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 py-0">
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="dialog_adv = false">{{$t("close")}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="findCourts">{{$t("search")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- /dialog advanced search -->
     
  <!-- Filters -->    
  <v-divider  v-show="filterList.length>0"></v-divider>       
  <v-row class="pa-0 ma-0 white" v-show="filterList.length>0">
    <v-col class="pa-0 ma-0" cols="12">
      <v-scroll-x-transition group hide-on-leave>
      <v-tooltip v-for="(f, i) in filterList" :key="i" right>
        <template v-slot:activator="{ on, attrs }">
          <v-chip  v-on='on' v-bind="attrs"
            class="ma-1"
            color="custom-color-accent"
            close
            small
            close-icon="mdi-delete"
            @click:close="removeFilter(f)" >
              {{f}}
            </v-chip>
          </template>
          <span>{{$t("removefilter")}}</span>
        </v-tooltip>
      </v-scroll-x-transition>
    </v-col>
  </v-row> 
  <!-- /Filters -->
  <v-divider></v-divider>
  <v-data-table :mobile-breakpoint="1081"  
    :headers="headers" 
    :items="courtsFiltered" 
    sort-by="id" 
    class="elevation-0 customoffwhite text--secondary pa-0 ma-0"
    :search="search"
    :loading="loading"
    :loading-text="$t('loading')"
    expanded.sync
    show-expand
    :footer-props="{'items-per-page-text':$t('rowsperpage') }"
  >
    <template v-slot:no-data> {{$t('noDataAvailable')}}</template>
    <template v-slot:[`item.ATIPOfficerId`]="{item}"><span class="text-capitalize">{{ getUser(item.ATIPOfficerId) }}</span></template>
    <template v-slot:[`item.dateAdvised`]="{item}">{{ item.dateAdvised | formatDate }}</template>
    <template v-slot:[`item.dateApplicationFiled`]="{item}">{{ item.dateApplicationFiled | formatDate }}</template>
    <template v-slot:[`item.dateOfDecision`]="{item}">{{ item.dateOfDecision | formatDate }}</template>
    <template v-slot:[`item.updated_at`]="{item}">{{ item.updated_at | formatDate }}</template>
    <template v-slot:expanded-item="{ headers, item }" elevation="0">
      <td class=" my-side-border" :colspan="headers.length" elevation="0">
        <span class="font-weight-bold text--secondary"> {{$t("comment")}}: </span
        ><span class="text--secondary" v-if="item.courtComment!=null">{{ item.courtComment }}</span>
        <span class="text--secondary font-weight-light" v-else>{{$t("No comment.") }}</span>
      </td>
    </template>
    <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
        <v-row class="ma-0 pa-0 text-right">
          <v-col cols="12" class="ma-0 pa-0">
             <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' @click="editItem(item)" v-bind="attrs" icon v-if="$checkPermission('Courts.Update')">
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("edit")}}</span>
              </v-tooltip>
          </v-col>
        </v-row>
      </template>
  </v-data-table>
      </div>
</v-card>
</template>

<script>

import { mapActions, mapState } from "vuex";
import moment from 'moment';
export default {
  props: ["current_req","courtsFiltered"],
   components: {
   },
  data() {
    return {
      nowDate: new moment().toISOString(),
      cu_req:{},
      filterList:[],
      dialog:false,
      editedIndex: -1,
      dialog_adv: false,
      activeCourt:{},
      search:"",
      sections:[{id:1,name:"Section 41"},{id:2,name:"Section 42"},{id:3,name:"Section 43"}],
      selectedSection:{},
      headers: [
        {
          text: this.$t("Court File Number"),
          value: "courtFileNumber"
        },
        {
          text: this.$t("Contact File Number"),
          value: "contactFileNumber"
        },
        {
          text: this.$t("ATIPOfficerId"),
          value: "ATIPOfficerId"
        },
        {
          text: this.$t("Date Advised"),
          value: "dateAdvised"
        },
        {
          text: this.$t("Date Application Filed"),
          value: "dateApplicationFiled"
        },
        {
          text: this.$t("Date of Decision"),
          value: "dateOfDecision"
        },
        { text: "", value: "action", sortable: false }
      ],
      filters: {
        contactName: "",
        contactFileNumber: "",
        courtFileNumber: "",
        courtComment:"",
        dateAdvised:[],
        dateApplicationFiled:[],
        dateOfDecision:[],
        requestId:[],
        ATIPOfficerId:[],
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t('New Court'): this.$t('Edit Court');
    },

    tableTitle() {
      return this.$t("Courts");
    },
    ...mapState("configuration", {
      configurations: "configurations",
      loading: "loading",
      requestConfiguration: "requestConfiguration",
    }),
    ...mapState("request", {
      requests: "requests"
    }),
    ...mapState({
      users: "users"
    }),
    ...mapState({
      user: "user"
    }),
    ...mapState("court", {
      loading: "loading",storeFilters:"filter"
    }),
    isDisable() {
      return (
        !this.activeCourt.contactName ||
        !this.activeCourt.courtFileNumber||
        !this.activeCourt.dateAdvised||
        !this.activeCourt.contactFileNumber
      );
    },
  },
  async created() {
    await this.retriveUsers();
    Object.assign(this.filters, this.storeFilters);
    Object.assign(this.cu_req, this.current_req);
    this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a); 
    this.filterList = this.filterList.filter(el=> el != "");
  },
  watch:{
    storeFilters: {
      handler(){
          Object.assign(this.filters, this.storeFilters);  
          this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a); 
                this.filterList = this.filterList.filter(el=> el != "");

      }, deep: true 
    },
  },
  methods: {
    getUser(id){
      return this.users.filter(i => i.id === id ).map(j=>j.first_name+' '+j.last_name)[0]
    },
    findCourts(){
      this.close();
      this.filterCourts(this.filters);
    },
    removeFilter(item){
      Object.keys(this.filters).forEach((key)=>{
      if( !Array.isArray(this.filters[key]) && (this.filters[key].text==item))
        this.filters[key]={text:"",value:""};        
      else if( !Array.isArray(this.filters[key]) && (this.filters[key]==item))
        this.filters[key]="";           

      else if(Array.isArray(this.filters[key]) && this.filters[key].map(a=>a.text).includes(item))
      this.filters[key]= this.filters[key].filter(a=>a.text!=item);

      else if(Array.isArray(this.filters[key]) && this.filters[key].includes(item))
      this.filters[key]= this.filters[key].filter(a=>a!=item);
      });
      this.filterCourts(this.filters);
    }, 
    getLookup(column) {
      return this.configurations
        .filter((filter) => filter.LookUpName === column)
        .map((item) => {
          let arr = [];
          arr["text"] =
            this.$vuetify.lang.current === "en"
              ? item.LookUpStringE
              : item.LookUpStringF;
          arr["value"] = item.LookUpKey;
          return arr;
        });
    },
    async saveCourt() {
      if (this.editedIndex == -1) {
        this.activeCourt.request_id = this.cu_req.id;
        this.activeCourt.ATIPOfficerId = this.user.id;
        this.createCourt(this.activeCourt).then(() => {
        let color="success";
        let alert="itemAddedAlert";
        this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
      });
      } else {
        this.activeCourt.request_id = this.cu_req.id;
        this.activeCourt.ATIPOfficerId = this.user.id;
        this.updateCourt(this.activeCourt).then(() => {
          let color="dark";
          let alert="itemUpdatedAlert";
          this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
      });
      }
      this.close();  
    },
    close() {
      this.dialog = false;
      this.dialog_adv = false;
      this.editedIndex = -1;
      this.activeCourt = {};
      this.cu_req={}
      Object.assign(this.cu_req, this.current_req);
    },
    openAdd() {
      this.dialog = true;
      this.editedIndex = -1;
      this.activeCourt = {};
      this.cu_req={}
      Object.assign(this.cu_req, this.current_req);
    },
    editItem(item) {
      this.dialog = true;
      this.editedIndex = this.courtsFiltered.indexOf(item);
      this.activeCourt = {};
      Object.assign(this.activeCourt,item)
      this.cu_req.id = this.activeCourt.request_id;
      this.activeCourt.section41?this.selectedSection=this.sections[0]:''
      this.activeCourt.section42?this.selectedSection=this.sections[1]:''
      this.activeCourt.section43?this.selectedSection=this.sections[2]:''
    },
    ...mapActions("court", ["createCourt","filterCourts", "updateCourt"]),
    ...mapActions("request", ["addReqCourt", "updateReqCourt"]),
    ...mapActions([ "retriveUsers"]), 
  }
};
</script>
<template>
  <div>
    <courts :current_req="null" :courtsFiltered="courtsFiltered"></courts>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import courts from "@/components/request/courtTab";

export default {
  name: "Court",
  components: {
    courts
  },

  data() {
    return {
      courtFilters:{},
    };
  },

  async created() {
    Object.assign(this.courtFilters, this.storeCourtFilters);
    this.filterCourts(this.courtFilters);
    this.clearCourtsFilter();
    await this.retriveCourts();
    await this.retriveRequests();
  },

  computed: {
    ...mapGetters('court', {
      'courtsFiltered':'courtsFiltered'
    }),
     ...mapState('court', {
      'storeCourtFilters':'filter'
    }),
  },
  watch: {
    courtsFiltered: {
      handler(){
        Object.assign(this.courtFilters, this.storeCourtFilters);
      }, deep: true 
    }
  },
  methods: {
    ...mapActions('court',['retriveCourts','filterCourts','clearCourtsFilter']),
    ...mapActions("request", ["setRequestItem", "retriveRequestItem","retriveRequests"]),
  }

};
</script>